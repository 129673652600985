<template>
  <div
    class="video-asset"
    :class="{
      'video-asset--no-controls': !controls
    }"
  >
    <div class="video-asset__player" v-if="controls">
      <SyncedMedia
        :src="src"
        :key="src"
        v-model="state"
        v-bind="$attrs"
        :volume="volume"
        :autoplay="false"
        :sync="isHostLike"
        :shortcuts="shortcuts"
        class="video-asset__video flipped"
        v-on="$listeners"
        ref="video"
      />
      <input type="range" min="0" max="100" v-model="progress" step="1" />
      <div class="video-asset__play-pause-btn" @click="toggle">
        <i class="fas fa-play" v-if="paused" />
        <i class="fas fa-pause" v-else />
      </div>
    </div>
    <SyncedMedia
      v-else
      :src="src"
      v-model="state"
      v-bind="$attrs"
      :volume="volume"
      :autoplay="false"
      :sync="isHostLike"
      :shortcuts="shortcuts"
      class="video-asset__video flipped"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import SyncedMedia from "@/modules/smart-media/components/SyncedMedia"
import MediaAsset from "@/components/Assets/MediaAsset"
import { Media } from "@/components/GroupTeams/Common/SmartMedia.vue"

export default MediaAsset.extend({
  name: "VideoAsset",
  components: {
    SyncedMedia
  },
  props: {
    volume: {
      type: Number,
      default: undefined
    },
    shortcuts: {
      type: Boolean,
      default: false
    },
    interrupt: {
      type: Boolean,
      default: true
    },
    src: String
  },
  data() {
    return {
      paused: false,
      value: 0,
      req: null
    }
  },
  beforeDestroy() {
    cancelAnimationFrame(this.req)
  },
  methods: {
    toggle() {
      const el = this.$refs.video?.$el
      if (this.paused) return el?.play()
      return el?.pause()
    }
  },
  mounted() {
    const loop = () => {
      this.req = requestAnimationFrame(() => {
        const el = this.$refs.video?.$el
        if (el == null) return
        this.value = Math.round((el?.currentTime / el?.duration) * 100)
        this.paused = el?.paused
        loop()
      })
    }
    loop()
  },
  watch: {
    volume(value) {
      if (!this.interrupt) return
      if (value === 0) return Media.rm(this._uid)
      Media.add(this._uid)
    }
  },
  computed: {
    progress: {
      get() {
        return this.value
      },
      set(value) {
        const el = this.$refs.video?.$el
        if (el == null) return
        const currentTime =
          Math.round(el?.duration * (value / 100) * 1000) / 1000
        el.currentTime = currentTime
      }
    }
  },
  created() {
    if (!this.interrupt) return
    Media.add(this._uid)
  },
  beforeDestroy() {
    if (!this.interrupt) return
    Media.rm(this._uid)
  }
})
</script>

<style lang="scss">
.video-asset {
  $block: &;

  display: flex;

  &--no-controls {
    justify-content: center;
  }

  &__play-pause-btn {
    visibility: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    opacity: 0.6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary_accent_color;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    & > * {
      color: #fff;
    }
  }

  &__player {
    display: flex;
    flex: 1 auto;
    justify-content: center;

    &:hover {
      #{$block}__play-pause-btn {
        visibility: visible;
      }
    }
    input[type="range"] {
      position: absolute;
      bottom: 20px;
      left: 10%;
      width: 80%;
      -webkit-appearance: none;
      background: #d3d3d3;
      outline: none;
      opacity: 0.6;
      border-radius: 5px;
      &:hover {
        opacity: 0.8;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        margin-top: -5px;
        margin-bottom: -5px;
        border-radius: 50%;
        background: $primary_accent_color;
        cursor: pointer;
      }
    }
  }

  &__video {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
