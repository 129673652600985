var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-asset",
      class: {
        "video-asset--no-controls": !_vm.controls,
      },
    },
    [
      _vm.controls
        ? _c(
            "div",
            { staticClass: "video-asset__player" },
            [
              _c(
                "SyncedMedia",
                _vm._g(
                  _vm._b(
                    {
                      key: _vm.src,
                      ref: "video",
                      staticClass: "video-asset__video flipped",
                      attrs: {
                        src: _vm.src,
                        volume: _vm.volume,
                        autoplay: false,
                        sync: _vm.isHostLike,
                        shortcuts: _vm.shortcuts,
                      },
                      model: {
                        value: _vm.state,
                        callback: function ($$v) {
                          _vm.state = $$v
                        },
                        expression: "state",
                      },
                    },
                    "SyncedMedia",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.progress,
                    expression: "progress",
                  },
                ],
                attrs: { type: "range", min: "0", max: "100", step: "1" },
                domProps: { value: _vm.progress },
                on: {
                  __r: function ($event) {
                    _vm.progress = $event.target.value
                  },
                },
              }),
              _c(
                "div",
                {
                  staticClass: "video-asset__play-pause-btn",
                  on: { click: _vm.toggle },
                },
                [
                  _vm.paused
                    ? _c("i", { staticClass: "fas fa-play" })
                    : _c("i", { staticClass: "fas fa-pause" }),
                ]
              ),
            ],
            1
          )
        : _c(
            "SyncedMedia",
            _vm._g(
              _vm._b(
                {
                  staticClass: "video-asset__video flipped",
                  attrs: {
                    src: _vm.src,
                    volume: _vm.volume,
                    autoplay: false,
                    sync: _vm.isHostLike,
                    shortcuts: _vm.shortcuts,
                  },
                  model: {
                    value: _vm.state,
                    callback: function ($$v) {
                      _vm.state = $$v
                    },
                    expression: "state",
                  },
                },
                "SyncedMedia",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }