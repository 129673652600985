import Vue from "vue"
import { mapGetters } from "vuex"

const PLAYER_CONFIG = JSON.stringify({
  controls: ["play", "progress", "current-time"],
  keyboard: { focused: false, global: false }
})

export default Vue.extend({
  name: "MediaAsset",
  props: {
    id: {
      type: String,
      default: undefined
    },
    controls: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "isModerator"]),
    ...mapGetters({ mission: "getCurrentMission" }),
    isHostLike() {
      return this.isHost || this.isModerator
    },
    state: {
      get() {
        const media = this.id
          ? this.$store.state.group.media?.[this.id]
          : this.$store.getters["group/media"]

        const currentTime = +media?.currentTime || +this.mission?.videoTime || 0

        return {
          paused: media?.paused === true,
          currentTime,
          playbackRate: media?.playbackRate || 1,
          startedAt: media?.startedAt || null,
          backwards: media?.backwards || null
        }
      },
      set(value) {
        this.$store.dispatch("group/updateMissionMediaState", {
          id: this.id || null,
          paused: value.paused,
          currentTime: value.currentTime || null,
          startedAt: value.startedAt || null,
          playbackRate: value?.playbackRate || 1,
          backwards: value.backwards || null
        })
      }
    },
    PLAYER_CONFIG() {
      return PLAYER_CONFIG
    }
  }
})
